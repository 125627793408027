.card {
    width: min(calc(50% - 30px), 600px);
    min-width: min(100%, 300px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: 0.3s;
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;
}

.card-img {
    width: 100%;
    border-bottom: 3px solid #14c99c;
    background-color: #131313;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}

.card-img img {
    width: 100%;
    aspect-ratio: 21/9;
    object-fit: cover;
    opacity: 0.3;
    transition: 0.2s;
}


.card-title {
    position: absolute;
    font-size: 1.2em;
    transition: 0.3s;
}

.card-footer {
    padding: 0px 15px;
    background-color: #3e2d4c;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    height: 0;
    transition: 0.3s;
    left: 0;
    top: 100%;
    position: absolute;
}
.card-footer > span, .card-footer > a {
    opacity: 0;
    transform: scale(0.8);
}

.card-footer a {
    text-decoration: none;
    padding: 5px 15px;
    border: 1px solid #14c99c;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s;
    color: #FFFFFF;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
}

.card-footer a > span {
	position: relative;
	top: 1px;
}

.card-footer a:hover {
    color: #FFFFFF;
    background-color: #14c99c;
}

.card:hover {
    overflow: visible;
    transform: scale(1.2);
    z-index: 2;
    filter: drop-shadow(0px 0px 10px #712582);
}

.card:hover .card-img img {
    opacity: 1;
}

.card:hover .card-title {
    opacity: 0;
    transform: translate(0, 20px);
}

.card:hover .card-footer {
    height: 35px;
    padding: 10px 15px;
}

.card:hover .card-footer * {
    opacity: 1;
}

@media only screen and (max-width: 768px) {
    .card:hover {
        transform: scale(1.1);
        z-index: 2;
    }
}