.menuWrapper .burgerIcon, .mobile-contact {
    display: none;
}

.menuWrapper .menu > .menu-items {
    display: flex;
    gap: 30px;
    font-size: 1.3em;
    z-index: 10;
}

.menuWrapper .menu > .menu-items > a {
    text-decoration: none;
    color: #FFFFFF;
    padding-bottom: 10px;
    margin-bottom: -10px;
    position: relative;
    transition: 0.2s;
}

.menuWrapper .menu > .menu-items > a > span {
    color: #14c99c;
}

.menuWrapper .menu > .menu-items > a:after {
    content: '';
    display: flex;
    position: absolute;
    width: 0px;
    height: 2px;
    background-color: #14c99c;
    top: 100%;
    transition: 0.3s;
}

.menuWrapper .menu > .menu-items > a:hover {
    color: #14c99c
}
.menuWrapper .menu > .menu-items > a:hover > span {
    color: #FFFFFF;
}
.menuWrapper .menu > .menu-items > a:hover:after {
    width: 100%;
}

/* MOBILE */
#menu_mobile {
    display: none;
}

@media only screen and (max-width: 768px) {    
    .menuWrapper .menu {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate(calc(-100% - 10px), 0);
        transition: 0.5s ease;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        width: min(200px, calc(100vw - 102px));
        overflow: hidden;
        height: calc(100vh - 40px);
        background-color: #0d1117;
        box-shadow: 5px 0px 10px #00000030;
        display: flex;
    }

    .menuWrapper .menu > .menu-items {
        flex-direction: column;
        font-size: 1em;
    }

    .menuWrapper .burgerIcon {
        display: flex;
        flex-direction: column;
        width: 36px;
        height: 36px;
        border: 2px solid #14c99c;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        gap: 20%;
        overflow: hidden;
        cursor: pointer;
    }
    .menuWrapper .burgerIcon:hover {
        background-color: #14c99c10;
    }

    .menuWrapper .burgerIcon > span {
        width: 70%;
        height: 2px;
        background-color: #FFFFFF;
        transition: 0.3s;
    }

    #menu_mobile:checked + div .menu {
        transform: translate(0, 0);
    }
    #menu_mobile:checked + div .burgerIcon > span:nth-child(1) {
        transform: translate(0px, 9px) rotate(45deg);
        background-color: #14c99c;
    }
    #menu_mobile:checked + div .burgerIcon > span:nth-child(2) {
        transform: translate(0px, 0px) rotate(-45deg);
        background-color: #14c99c;
    }
    #menu_mobile:checked + div .burgerIcon > span:nth-child(3) {
        transform: translate(0, 20px);
        opacity: 0;
    }

    .menu-backdrop {
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;    
    }
    #menu_mobile:checked + div .menu-backdrop {
        visibility: visible;
        background-color: #00000060;
        z-index: -1;
    }

    .mobile-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        font-size: 1.4em;
        margin-bottom: 30px;
    }
    .mobile-contact a {
        color: #FFFFFF;
        text-decoration: none;
        transition: 0.2s;
    }
    .mobile-contact a:hover {
        color: #14c99c;
    }
}