#projects {
    background-image: url(http://localhost:3000/static/media/bg-about.ef8327e1781a4202aa80.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}
#projects .container {
    z-index: 2;
}

.card-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}

#projects .blackhole {
    position: absolute;
    top: 80px;
    left: 10px;
    width: min(20%, 250px);
    animation: blackhole 120s infinite alternate;
    z-index: 0;
}

#projects .planet {
    position: absolute;
    bottom: -40px;
    right: 10px;
    width: min(20%, 250px);
    animation: planet 2s infinite alternate;
    z-index: 0;
}

@media only screen and (max-width: 768px) {
    .card-group {
        flex-direction: column;
        align-items: center;
    }
}

@keyframes planet {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(0, 20px);
    }
}

@keyframes blackhole {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}