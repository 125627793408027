
.timeline-item {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    z-index: 1;
}

.timeline-company {
    display: flex;
    gap: 30px;
}

.timeline-item img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 0px 15px 5px #712582;
    border: 2px solid #000000;
}

.timeline-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.timeline-info > div > div {
    display: flex;
    flex-direction: column;
    gap: 5px
}
.timeline-role {
    font-size: 1.4em;
    padding-right: 30px;
}
.timeline-date {
    color: #5c647b;
    font-size: 0.7em;
}
.timeline-text {
    white-space: pre-line;
}

.timeline-techs {
    margin-top: 15px;
    display: flex;
    gap: 5px;
    align-items: flex-start;
    font-size: 0.8em;
}
.timeline-techs span:nth-child(1) {
    width: max-content;
    white-space: nowrap;
}

@media only screen and (max-width: 768px) {
    .timeline-company {
        gap: 0;
    }
    .timeline-company .timeline-icon {
        order: 1;
    }
    .timeline-company .timeline-date {
        order: 2;
    }
    .timeline-techs {
        flex-direction: column;
        font-size: 0.6em;
    }
    .timeline-role {
        font-size: 1.2em;
    }
    .timeline-text {
        font-size: 0.8em;
    }
}