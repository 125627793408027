.timeline {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.timeline:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 32px;
    width: 2px;
    height: 100%;
    border-radius: 10px;
    background: rgb(20,201,156);
    background: -moz-linear-gradient(180deg, rgba(20,201,156,1) 0%, #0f1724 100%);
    background: -webkit-linear-gradient(180deg, rgba(20,201,156,1) 0%, #0f1724 100%);
    background: linear-gradient(180deg, rgba(20,201,156,1) 0%, #0f1724 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#14c99c",endColorstr="#712582",GradientType=1); 
    z-index: 0;
}
