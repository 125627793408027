#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#footer .subtitle {
    font-size: 1.1em;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 60px;
    color: #5c647b;
}

#footer .contact {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 80px;
}

#footer .contact > div > div {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
}

#footer .contact > div > div .contact-icon {
    font-size: 2em;
}

#footer .contact > div > div a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 1.1em;
    transition: 0.2s;
}

#footer .contact > div > div a:hover {
    color: #14c99c;
}

footer {
    width: 100%;
    text-align: center;
    background: #0c121c;
    padding: 15px;
    font-size: 0.8em;
}

@media only screen and (max-width: 768px) {
    #footer .contact {
        flex-direction: column;
        padding-bottom: 0;
    }
}
