.banner {
    display: flex;
    flex: 1;
    align-items: center;
    transform: translate(-500px, 0);
    opacity: 0;
    animation: banner 1s forwards;
    z-index: 1;
}

.banner > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;
}


.banner .info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.banner .info > div {
    display: flex !important;
    flex-wrap: wrap;
}

.banner .title {
    font-size: 2em;
}

.banner .title-text {
    font-size: 1.2em;
    color: #5c647b;
}

.banner .info a {
    text-decoration: none;
    width: max-content;
    border: 1px solid #614991;
    border-radius: 20px;
    padding: 10px 15px;
    color: #FFFFFF;
    transition: 0.3s;
    overflow: hidden;
    position: relative;
}
.banner .info a:after {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    height: 100%;
    width: 0;
    background-color: #614991;
    z-index: -1;
    transition: 0.3s;
    transform: skew(-45deg);
}
.banner .info a:hover:after {
    width: calc(100% + 20px);
}

@keyframes banner {
    from {
        transform: translate(-500px, 0);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}