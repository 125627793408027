.header {
    padding-block: 30px;
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 60px);
    flex-direction: column;
}

.header > .header-bg {
    position: absolute;
    display: flex;
    align-items: center;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
    background: hsla(220, 100%, 16%, 1);
    background: radial-gradient(circle, hsla(220, 100%, 16%, 1) 10%, hsla(217, 41%, 10%, 1) 100%);
    background: -moz-radial-gradient(circle, hsla(220, 100%, 16%, 1) 10%, hsla(217, 41%, 10%, 1) 100%);
    background: -webkit-radial-gradient(circle, hsla(220, 100%, 16%, 1) 10%, hsla(217, 41%, 10%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#001C52", endColorstr="#0F1724", GradientType=1 );
    opacity: 0;
    animation: gradient 1s forwards;
}

.header > .header-bg > .background {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.header > .header-bg > .laptop-img {
    position: absolute;
    right: 0;
    transform: translate(500px, 0);
    width: min(950px, 55%);
    animation: laptop 1s forwards;
}

@keyframes laptop {
    from {
        transform: translate(500px, 0);
    }
    to {
        transform: translate(0, 0);
    }
}

@keyframes gradient {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}