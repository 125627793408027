.logo {
    font-size: 1.6em;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    color: #FFFFFF;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

@media only screen and (max-width: 768px) {
    .logo {
        font-size: 1.4em;
    }
}