#about_me {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: url(../../../assets//images/bg-about.jpg);
    background-position: center;
    background-size: cover;
}

#about_me .container {
    z-index: 2;
}

#about_me .info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#about_me .info img {
    width: min(200px, 50%);
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 5px #712582;
    margin-bottom: 30px;
}

#about_me .presentation-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    padding: 30px;
    width: min(80%, 600px);
    font-size: 1.2em;
}

#about_me .rocket {
    position: absolute;
    bottom: 0;
    right: 0;
    width: min(20%, 600px);
    z-index: 0;
    animation: rocket 5s infinite alternate;
}

#about_me .astronaut {
    position: absolute;
    top: 80px;
    left: 0;
    width: min(20%, 600px);
    z-index: 0;
    animation: astronaut 2s infinite alternate;
}

@keyframes astronaut {
    from {
        transform: translate(0, 0px);
    }
    to {
        transform: translate(0, 20px);
    }
}

@keyframes rocket {
    from {
        transform: translate(0, 0px);
    }
    to {
        transform: translate(0, 20px);
    }
}

@media only screen and (max-width: 768px) {
    #about_me .presentation-text {
        font-size: 1em;
        text-align: center;
    }
}